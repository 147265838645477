import { DotsVerticalIcon, PencilIcon, ThumbDownIcon, ThumbUpIcon } from "@heroicons/react/outline";
import { DownloadIcon } from "@heroicons/react/solid";
import { useMutation } from "@tanstack/react-query";
import { useFlag } from "@unleash/proxy-client-react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { OrderRequisition } from "@web/common";
import { useModalContext } from "@web/common/contexts/ModalContext";
import { Dropdown, DropdownItem, IconButton, RegularButton, RegularDropdownItem } from "@web/ui";
import { triggerFileDownload } from "@web/utils";

import { exportOrderToExcel } from "src/api/exportOrderToExcel";
import RejectRequisitionModal from "src/components/modals/RejectRequisitionModal";
import routes from "src/config/routes";
import { useSystemMessage } from "src/hooks/useSystemMessage";
import { clearGatherBasketEntries, useAppDispatch } from "src/store";

interface Props {
  isRfQRequisition: boolean;
  requisitionId: string;
  requisitionStatus: OrderRequisition["status"];
  canDownloadExcel?: boolean;
}

const RequisitionCTAButtons: React.FC<Props> = ({
  isRfQRequisition,
  requisitionId,
  requisitionStatus,
  canDownloadExcel,
}) => {
  const { t } = useTranslation();
  const { openModal, closeModal } = useModalContext();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { setSystemMessage } = useSystemMessage();
  const hasSelectSupplierFeature = useFlag("select-supplier");

  const onRequisitionEdit = () => {
    navigate(`${routes.orderDetails}/${requisitionId}/edit`);
  };

  const handleApproveRequisitionClick = () => {
    dispatch(clearGatherBasketEntries());
    navigate(`${routes.orderDetails}/${requisitionId}/approve`);
  };

  const isAnyActionAvailable = canDownloadExcel;

  const { mutate: downloadExcel } = useMutation({
    mutationKey: ["exportOrderToExcel"],
    mutationFn: () =>
      exportOrderToExcel({
        orderId: requisitionId,
      }),

    onSuccess: (file) => {
      setSystemMessage({
        type: "success",
        message: "The requisition will be downloaded soon.",
      });
      triggerFileDownload({
        file,
        fileNameWithExtension: `S2S_${requisitionId}.xlsx`,
      });
    },
    onError: () => {
      setSystemMessage({
        type: "failure",
        message: "There was an issue while downloading the requisition. Please try again.",
      });
    },
  });

  const contextMenuItems: DropdownItem[] = [
    ...(canDownloadExcel
      ? [
          {
            key: "downloadAsXlsx",
            renderComponent: () => (
              <RegularDropdownItem
                label="Download requisition as XLSX"
                variant="basic"
                LeadingIcon={DownloadIcon}
                onClick={() => {
                  downloadExcel();
                }}
              />
            ),
          },
        ]
      : []),
  ];

  return (
    <div className="flex h-min gap-3">
      {requisitionStatus !== "PURCHASER_REJECTED" && (
        <div className="flex h-min gap-2">
          {!hasSelectSupplierFeature && (
            <>
              <RegularButton
                variant="secondary"
                size="large"
                label={t("layouts.buttons.editItems")}
                LeadingIcon={PencilIcon}
                onClick={onRequisitionEdit}
              />
              <div className="w-0.5 bg-neutral_200" role="presentation" />
            </>
          )}
          <RegularButton
            variant="danger"
            size="large"
            label={t("layouts.buttons.decline")}
            LeadingIcon={ThumbDownIcon}
            onClick={() =>
              openModal(
                <RejectRequisitionModal
                  isRfQRequisition={isRfQRequisition}
                  requisitionId={requisitionId}
                  closeModal={closeModal}
                />
              )
            }
          />
          <RegularButton
            variant="primary"
            size="large"
            label={
              isRfQRequisition
                ? t("layouts.buttons.approveRfqRequisition")
                : t("layouts.buttons.approveOrder")
            }
            LeadingIcon={ThumbUpIcon}
            onClick={handleApproveRequisitionClick}
          />
        </div>
      )}
      {isAnyActionAvailable && (
        <Dropdown hAlignTo="end" vAlignTo="bottom" items={contextMenuItems}>
          <IconButton
            size="large"
            variant="secondary"
            shape="square"
            label="More actions"
            Icon={DotsVerticalIcon}
            data-testid="contextMenu_button"
            isControlled
          />
        </Dropdown>
      )}
    </div>
  );
};

export default RequisitionCTAButtons;
