import { useCallback } from "react";

import { BasketEntry } from "@web/common";

import { clearGatherBasketEntry, setGatherBasketQuantity, useAppDispatch } from "../store";

export const useSetQuantity = () => {
  const dispatch = useAppDispatch();

  const setQuantity = useCallback(
    (basketEntry: BasketEntry) => (q: number) => {
      if (q === 0) {
        dispatch(clearGatherBasketEntry(basketEntry.sku.id));
      } else {
        dispatch(setGatherBasketQuantity({ id: basketEntry.sku.id, quantity: q }));
      }
    },
    [dispatch]
  );

  return { setQuantity };
};
