import { NoteBox } from "@web/common";
import ActivityBox from "@web/common/layouts/ActivityBox";
import { PriceBox } from "@web/common/layouts/PriceBox";
import { OrderRequisition } from "@web/common/network/model";
import { containerPadding } from "@web/ui";

import RequisitionTopbar from "./RequisitionTopbar";

interface Props {
  children: React.ReactNode;
  requisition: OrderRequisition;
}

const RequisitionLayout: React.FC<Props> = ({ children, requisition }) => {
  return (
    <div className="h-full flex flex-col">
      <RequisitionTopbar requisition={requisition} />
      <div className="bg-neutral_100 h-full flex flex-col">
        <div className={`${containerPadding} flex py-6`}>
          <div className="w-4/6 mr-3">{children}</div>
          <div className="ml-7 h-min w-1/3">
            <PriceBox order={requisition} className="mb-3" />
            <ActivityBox order={requisition} />
            <NoteBox order={requisition} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequisitionLayout;
