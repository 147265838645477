import { useFlag } from "@unleash/proxy-client-react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { RfqLineItem } from "@web/common/components/RfqLineItem";
import { RfqTotalPriceInfo } from "@web/common/components/RfqTotalPriceInfo";
import { OrderRequisition } from "@web/common/network/model";
import { OrderReqService } from "@web/common/services/OrderRequisition";
import { Heading, Paragraph } from "@web/ui";

import RequestTable from "../../../components/tables/RequestTable";
import QuotationLayout from "../../../layouts/QuotationLayout";
import { clearGatherBasketEntries, useAppDispatch } from "../../../store";

type QuotationDetailsProps = {
  quotation: OrderRequisition;
};

const QuotationDetails: React.FC<QuotationDetailsProps> = ({ quotation }) => {
  const dispatch = useAppDispatch();
  const isOrderNameEnabled = useFlag("order-name");

  useEffect(() => {
    dispatch(clearGatherBasketEntries());
  }, [dispatch]);

  const { t } = useTranslation();
  const isWaitingForQuote =
    quotation.status === "SUPPLIER_QUOTE_ACKNOWLEDGED" ||
    quotation.status === "SUPPLIER_QUOTE_PENDING";

  return (
    <QuotationLayout quotation={quotation}>
      {isOrderNameEnabled && (
        <>
          <Heading size="100">{quotation.subject}</Heading>
          <hr className="my-4" />
        </>
      )}
      <div className="flex flex-row w-full items-center justify-between">
        <Heading size="300">{t("pages.quotationDetails.requestedItems")}</Heading>
        <RfqTotalPriceInfo
          isWaitingForQuote={isWaitingForQuote}
          totalGrossAmount={OrderReqService.getTotalGrossAmount(quotation)}
        />
      </div>
      {quotation.items.length > 0 && (
        <>
          <Paragraph
            size="300"
            color="text-textIcon-blackSecondary"
            className="pb-3 uppercase mt-6"
          >
            {t("pages.quotationDetails.offeredItems")}
          </Paragraph>
          <RequestTable requisition={quotation} />
        </>
      )}
      {!isWaitingForQuote && (
        <Paragraph size="300" color="text-textIcon-blackSecondary" className="pb-3 uppercase mt-6">
          {t("pages.quotationDetails.requestedItems")}
        </Paragraph>
      )}
      <div>
        {quotation.rfqItems?.map((item) => (
          <RfqLineItem isRemoved={false} key={item.id} quantity={item.quantity} rfqfItem={item} />
        ))}
      </div>
    </QuotationLayout>
  );
};

export default QuotationDetails;
