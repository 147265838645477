import { Port } from "@web/models";
import { OptionType } from "@web/ui";

import { createFleet } from "src/api/createFleet";
import { updateFleet } from "src/api/updateFleet";
import { AppCustomer, AppFleet } from "src/typegens";

import { EditFleetModalBody } from "./EditFleetModalBody";
import {
  FleetFormType,
  FleetPermissions,
  StocktakeCurrencyCodes,
  StocktakeCurrencyCodesType,
} from "./schema";

interface Props {
  fleet?: AppFleet;
  customers: AppCustomer[];
  ports: Record<string, Port[]>;
  onUpdate: () => void;
  onCancel: () => void;
}

export const EditFleetModal: React.FC<Props> = ({
  fleet,
  customers,
  onUpdate,
  onCancel,
  ports,
}) => {
  const customerOptions: OptionType[] = customers.map((customer) => ({
    label: customer.name,
    value: customer.id as string,
  }));

  const defaultCustomerValue: OptionType | undefined = customerOptions.find(
    (f) => f.value === fleet?.customerId
  );

  const permissionsOptions: OptionType[] = Object.keys(FleetPermissions).map((permission) => ({
    label: permission,
    value: permission,
  }));

  const defaultPermissionsValue: OptionType | undefined = permissionsOptions.find(
    (f) => f.value === fleet?.permissions[0]
  );

  const stocktakeCurrencyOptions: OptionType<StocktakeCurrencyCodesType>[] =
    StocktakeCurrencyCodes.map((currencyCode) => ({
      label: currencyCode,
      value: currencyCode,
    }));

  const defaultCurrencyOption: OptionType<StocktakeCurrencyCodesType> | undefined =
    stocktakeCurrencyOptions.find((f) => f.value === fleet?.stocktakeCurrency);

  const onSubmit = (data: FleetFormType) =>
    (fleet ? updateFleet(fleet.id, data) : createFleet(data)).then(() => onUpdate());

  const defaultValues: Partial<FleetFormType> = {
    fleetName: fleet ? fleet.name : "",
    customer: defaultCustomerValue
      ? {
          label: defaultCustomerValue.label,
          value: defaultCustomerValue.value,
        }
      : undefined,
    permissions: defaultPermissionsValue
      ? {
          label: defaultPermissionsValue.label,
          value: defaultPermissionsValue.value,
        }
      : undefined,
    businessUnitId: fleet ? fleet.businessUnitId : "",
    adminId: fleet ? fleet.adminId : "",
    adminReferenceId: fleet ? fleet.adminReferenceId : "",
    invoiceAccounts: fleet ? fleet.invoiceAccounts : [],
    assignedPortIds: fleet ? fleet.assignedPortIds : [],
    allow: {
      createRfq: fleet ? fleet.allow.createRfq : false,
      fmsIntegration: fleet ? fleet.allow.fmsIntegration : false,
      punchOutIntegration: fleet ? fleet.allow.punchOutIntegration : false,
      editable: fleet ? fleet.allow.editable : false,
      customerOrderIdOptional: fleet ? fleet.allow.customerOrderIdOptional : false,
      reorder: fleet ? fleet.allow.reorder : false,
      offline: fleet ? fleet.allow.offline : false,
      offlineDraft: fleet ? fleet.allow.offlineDraft : false,
      foIntegration: fleet ? fleet.allow.foIntegration : false,
      stocktaking: fleet ? fleet.allow.stocktaking : false,
      manualNetworkState: fleet ? fleet.allow.manualNetworkState : false,
      sortPurchaseOrderLines: fleet ? fleet.allow.sortPurchaseOrderLines : false,
      orderExtraItems: fleet ? fleet.allow.orderExtraItems : false,
    },
    srn: fleet?.srn || "",
    stocktakeCurrency: defaultCurrencyOption
      ? {
          label: defaultCurrencyOption.label,
          value: defaultCurrencyOption.value,
        }
      : undefined,
  };

  return (
    <EditFleetModalBody
      ports={ports}
      onSubmit={onSubmit}
      permissionsOptions={permissionsOptions}
      customerOptions={customerOptions}
      stocktakeCurrencyOptions={stocktakeCurrencyOptions}
      defaultFormValues={defaultValues}
      isEditing={!!fleet}
      onCancel={onCancel}
    />
  );
};
