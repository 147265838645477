import { TrashIcon } from "@heroicons/react/outline";
import MinusIcon from "@heroicons/react/solid/MinusIcon";
import PlusIcon from "@heroicons/react/solid/PlusIcon";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { getSalesEntityPrice, getSalesEntityQuantity } from "@web/common/utils";
import { Money } from "@web/models";
import { Heading, IconButton, Paragraph, RegularButton } from "@web/ui";
import { formatMoney } from "@web/utils";

import CartSVG from "../../../icons/Cart.svg?react";
import { ProductSku } from "../../../models/ProductSku";
import { LeadTime } from "../../LeadTime";
import { TotalUnitOfMeasure } from "../../TotalUnitOfMeasure";
import MinimumOrderQuantity from "./components/MinimumOrderQuantity";

interface Props {
  sku: ProductSku;
  quantityInBasket: number;
  onAddToCart(): void;
  handlePlus(): void;
  handleMinus(): void;
  handleChange(val: number): void;
  lineItemTotal: Money | null;
  maxLength?: number;
}

const filterInt = (value: string) => {
  if (/^[-+]?\d+$/.test(value)) {
    return Number(value);
  } else {
    return NaN;
  }
};

export const AddToCartUI: React.FC<Props> = ({
  sku,
  quantityInBasket = 0,
  onAddToCart,
  handlePlus,
  handleMinus,
  lineItemTotal,
  handleChange,
  maxLength = 4,
}) => {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState("");
  const minimumQuantity = sku.about?.generalInformation?.minimumOrderQuantity || 1;
  const salesEntityQuantity = sku.salesEntityQuantity;
  const minimumQuantityNumber =
    minimumQuantity !== undefined ? minimumQuantity / salesEntityQuantity : 1 / salesEntityQuantity;

  useEffect(() => {
    setInputValue(quantityInBasket.toString());
  }, [quantityInBasket]);

  const handleInputChange = (e: React.FormEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value;
    if (!isNaN(filterInt(value)) || value === "") {
      setInputValue(value);
    }
  };

  const canDecrease = Number(inputValue) > minimumQuantityNumber;
  const isBelowMinimum = Number(inputValue) < minimumQuantityNumber;

  const onBlur = () => {
    const input = parseInt(inputValue);
    if (input === 0) {
      handleChange(0);
    } else if (input < minimumQuantityNumber) {
      handleChange(minimumQuantityNumber);
    } else {
      handleChange(input);
    }
  };
  const isPlusButtonDisabled = (parseInt(inputValue) + 1).toString().length > maxLength;

  const onPlusClick = () => {
    if (isPlusButtonDisabled) {
      return;
    }
    handlePlus();
  };

  return (
    <div className="w-20 px-4 py-2 border rounded-md shadow-lg bg-neutral_0">
      {/* Selected SKU Area */}
      <div className="flex items-baseline py-4">
        <Heading size="300">{formatMoney(getSalesEntityPrice(sku))}</Heading>
        <div className="pl-2">
          <Paragraph size="300" color="text-textIcon-blackSecondary">
            {`${formatMoney(sku.price.costPrice)} / ${sku.measurementUnit}`}
          </Paragraph>
        </div>
      </div>

      <LeadTime leadTime={sku.leadTime} />
      <hr className="my-4" />

      {/* Interaction area */}
      {quantityInBasket ? (
        <div className="flex justify-between">
          <IconButton
            size="small"
            variant="secondary"
            shape="circle"
            label="Decrease"
            Icon={canDecrease ? MinusIcon : TrashIcon}
            onClick={handleMinus}
            data-testid="decrease"
          />
          <input
            value={inputValue}
            onChange={handleInputChange}
            onBlur={onBlur}
            className="h-6 border rounded text-center w-full mx-2"
            aria-label="Item Quantity"
            maxLength={maxLength}
          />
          <IconButton
            size="small"
            variant="secondary"
            shape="circle"
            label="Increase"
            Icon={PlusIcon}
            onClick={onPlusClick}
            data-testid="increase"
            disabled={isPlusButtonDisabled}
          />
        </div>
      ) : (
        <div className="w-full mt-4 mb-2">
          <RegularButton
            size="large"
            variant="primary"
            width="container"
            LeadingIcon={CartSVG}
            label={t("common.cards.addToCart.addToBasket")}
            onClick={onAddToCart}
            data-testid="addToBasket"
          />
        </div>
      )}

      {/* Info area */}
      <div className="w-full mt-4 mb-2">
        {quantityInBasket ? (
          <div className="flex flex-col">
            <TotalUnitOfMeasure
              className="justify-center"
              quantityInBasket={quantityInBasket}
              salesEntityQuantity={getSalesEntityQuantity(sku)}
              measurementUnit={sku.measurementUnit}
              variant="positive"
            />
            {isBelowMinimum ? (
              <MinimumOrderQuantity sku={sku} isError={true} />
            ) : (
              <div className="text-center">{!!lineItemTotal && formatMoney(lineItemTotal)}</div>
            )}
          </div>
        ) : (
          <MinimumOrderQuantity sku={sku} />
        )}
      </div>
    </div>
  );
};
