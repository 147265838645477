import { createAction } from "@reduxjs/toolkit";

import { OrderType } from "@web/common/models/OrderType";
import { ProductSku } from "@web/common/models/ProductSku";
import { Vessel } from "@web/common/models/Vessel";
import { DutyFreeDeclarationType, OrderItem, RfqItem } from "@web/common/network/model";
import { Port } from "@web/models";
import { ToastMessage } from "@web/ui";

export const setUserInactivitySpanAction = createAction<number>("auth/set-user-inactivity-span");
export const setScopeAction = createAction<Array<string>>("auth/set-scope");

export const setSystemMessage = createAction<ToastMessage>("system/set-message");
export const clearSystemMessage = createAction<{ id: string }>("system/clear-message");

export const setGatherPort = createAction<Port>("gather/set-port");
export const setGatherDraftId = createAction<string>("gather/set-draft-id");
export const setPreconfigureOrderSetup = createAction<{
  port?: Port;
  deliveryDate?: string;
  dutyFreeDeclaration?: DutyFreeDeclarationType;
  // TODO #8959: Change to AppCatalogConfiguration["type"] once this model is available in the API
  orderType?: OrderType;
}>("gather/set-preconfigure-order-setup");
export const setDeliveryDate = createAction<Date>("gather/set-delivery-date");
export const setGatherVessel = createAction<Vessel>("gather/set-vessel");
export const setGatherBasketEntry = createAction<{
  quantity: number;
  sku: ProductSku;
}>("gather/set-basket-entry");
export const setGatherBasketQuantity = createAction<{ id: string; quantity: number }>(
  "gather/set-basket-quantity"
);
export const setGatherBasketRfqEntry = createAction<{
  quantity: number;
  item: RfqItem;
}>("gather/set-basket-rfq-entry");
export const clearGatherBasketEntry = createAction<string>("gather/clear-basket-entry");

export const clearGatherBasket = createAction("gather/clear-gather-basket");

export const clearGatherBasketEntries = createAction("gather/clear-gather-basket-entries");

export const setGatherBasketForRequisition = createAction<{
  isQuotation?: boolean;
  requisitionId: string;
  items: OrderItem[];
  vessel: Vessel;
  port: Port;
}>("gather/set-basket-for-requisition");

export const setGatherBasketForRfq = createAction<{
  requisitionId: string;
  items: RfqItem[];
  vessel: Vessel;
  port: Port;
}>("gather/set-basket-for-rfq");

export const setOrderComments = createAction<{
  orderComments?: string;
}>("gather/set-order-comments");

export const clearOrderComments = createAction("gather/clear-order-comments");
