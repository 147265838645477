import ActivityBox from "@web/common/layouts/ActivityBox";
import { PriceBox } from "@web/common/layouts/PriceBox";
import { OrderRequisition } from "@web/common/network/model";
import { containerPadding } from "@web/ui";

import QuotationTopbar from "./QuotationTopbar";

interface Props {
  children: React.ReactNode;
  quotation: OrderRequisition;
}

const QuotationLayout: React.FC<Props> = ({ children, quotation }) => {
  return (
    <div className="h-full flex flex-col">
      <QuotationTopbar quotation={quotation} />
      <div className="bg-neutral_100 h-full flex flex-col">
        <div className={`${containerPadding} flex py-6`}>
          <div className="w-4/6 mr-3">{children}</div>
          <div className="ml-7 h-min w-1/3">
            <PriceBox order={quotation} className="mb-3" />
            <ActivityBox order={quotation} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuotationLayout;
