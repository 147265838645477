import { DefaultError, useMutation } from "@tanstack/react-query";

import { deleteDraft } from "src/api/deleteDraft";
import { useSystemMessage } from "src/hooks/useSystemMessage";

type UseDeleteDraftMutationArgs = {
  onSuccess?: (deletedDraftId: string) => void;
  onError?: (draftToDeleteId: string, error: Error) => void;
};

export const useDeleteDraftMutation = ({ onError, onSuccess }: UseDeleteDraftMutationArgs) => {
  const { setSystemMessage } = useSystemMessage();

  return useMutation<unknown, DefaultError, string>({
    mutationKey: ["deleteDraft"],
    mutationFn: deleteDraft,
    onSuccess: (_, deletedDraftId) => {
      setSystemMessage({
        type: "success",
        message: "Your draft was successfully deleted",
      });

      if (onSuccess) {
        onSuccess(deletedDraftId);
      }
    },
    onError: (error, draftToDeleteId) => {
      setSystemMessage({
        type: "failure",
        message: "There was an error while deleting your draft",
      });

      if (onError) {
        onError(draftToDeleteId, error);
      }
    },
  });
};
