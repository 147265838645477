import { DotsVerticalIcon } from "@heroicons/react/outline";
import { useFlag } from "@unleash/proxy-client-react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

import {
  DividerDropdownItem,
  Dropdown,
  DropdownItem,
  IconButton,
  RegularDropdownItem,
} from "@web/ui";

import routes from "../../../config/routes";
import { useAppScopes } from "../../../hooks/useAppScopes";
import { useLogout } from "../../../hooks/useLogout";
import Logo from "../../../icons/source2seaHeaderLogo.svg";
import { CreateNewOrderButton } from "./CreateNewOrderButton";

export const Header: React.FC = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { logout } = useLogout();

  const canManageCustomers = useAppScopes("read:customers", "write:customers");
  const canManageFleets = useAppScopes("read:fleets", "write:fleets");
  const canManageVessels = useAppScopes("read:vessels", "read:vesselTokens");
  const canManageUserAssignments = useAppScopes("read:userAssignments", "write:userAssignments");
  const hasSelectSupplierFeature = useFlag("select-supplier");

  // Only show divider if there are any `danger` actions available, and there is at least one `non-danger` action available
  const isDangerZoneDividerVisible =
    canManageCustomers || canManageFleets || canManageVessels || canManageUserAssignments;

  const manageCustomerDropdownItem: DropdownItem = {
    key: "manageCustomers",
    renderComponent: () => (
      <RegularDropdownItem
        label={t("layouts.base.settings.manageCustomers")}
        variant="basic"
        onClick={() => {
          navigate(routes.manageCustomers);
        }}
      />
    ),
  };

  const manageFleetsDropdownItem: DropdownItem = {
    key: "manageCustomers",
    renderComponent: () => (
      <RegularDropdownItem
        label={t("layouts.base.settings.manageFleets")}
        variant="basic"
        onClick={() => {
          navigate(routes.manageFleets);
        }}
      />
    ),
  };

  const manageVesselsDropdownItem: DropdownItem = {
    key: "manageCustomers",
    renderComponent: () => (
      <RegularDropdownItem
        label={t("layouts.base.settings.manageVessels")}
        variant="basic"
        onClick={() => {
          navigate(routes.manageVessels);
        }}
      />
    ),
  };

  const managePurchasersDropdownItem: DropdownItem = {
    key: "manageCustomers",
    renderComponent: () => (
      <RegularDropdownItem
        label={t("layouts.base.settings.manageUsers")}
        variant="basic"
        onClick={() => {
          navigate(routes.manageUsers);
        }}
      />
    ),
  };

  const signOutDropdownItem: DropdownItem = {
    key: "manageCustomers",
    renderComponent: () => (
      <RegularDropdownItem
        label={t("layouts.base.settings.signOut")}
        variant="danger"
        onClick={() => {
          logout();
        }}
      />
    ),
  };

  const dropdownItems: DropdownItem[] = [
    ...(canManageCustomers ? [manageCustomerDropdownItem] : []),
    ...(canManageFleets ? [manageFleetsDropdownItem] : []),
    ...(canManageVessels ? [manageVesselsDropdownItem] : []),
    ...(canManageUserAssignments ? [managePurchasersDropdownItem] : []),
    ...(isDangerZoneDividerVisible
      ? [
          {
            key: "dangerZoneDivider",
            renderComponent: () => <DividerDropdownItem />,
          },
        ]
      : []),
    signOutDropdownItem,
  ];

  return (
    <div className="w-full bg-neutral_1000 ">
      <div className="containerPadding px-8 flex flex-row justify-between items-center w-full h-[60px]">
        <Link to={routes.orders}>
          <img src={Logo} className="text-3xl py-2" alt="logo" />
        </Link>
        <div className="flex items-center gap-4">
          {!hasSelectSupplierFeature && <CreateNewOrderButton />}
          <Dropdown hAlignTo="end" vAlignTo="bottom" items={dropdownItems}>
            <IconButton
              size="large"
              variant="secondary"
              shape="square"
              label="Menu"
              Icon={DotsVerticalIcon}
              isControlled
            />
          </Dropdown>
        </div>
      </div>
    </div>
  );
};
