import { ProductSku } from "@web/common/models/ProductSku";
import { getSalesEntityPrice } from "@web/common/utils";
import { Heading, Paragraph } from "@web/ui";
import { formatMoney, imagor } from "@web/utils";

import Tag from "../../icons/Tag.svg";

interface Props {
  sku: ProductSku;
}

export const ProductDetailsHeader = ({ sku }: Props) => {
  return (
    <>
      <Paragraph size="100" color="text-textIcon-blackSecondary" className="uppercase">
        {sku.supplierInformation.name}
      </Paragraph>
      <div className="bg-neutral_200 rounded-lg my-1 px-2 py-2 flex flex-row w-fit">
        <img src={Tag} className="pr-2" />
        <Paragraph
          size="200" /* color="text-text-whiteDisabled" */
          // TODO #6444: Fixme, there is no such color in tailwind config
        >
          Item no. {sku.supplierInformation.supplierItemId}
        </Paragraph>
      </div>
      {sku.about?.name && <Heading size="200">{sku.about?.name}</Heading>}
      <div className="pb-6">
        {!!sku.about?.quality?.brand && (
          <Paragraph
            size="200" /* color="text-text-whiteDisabled" */
            // TODO #6444: Fixme, there is no such color in tailwind config
          >
            By {sku.about?.quality?.brand}
          </Paragraph>
        )}
      </div>
      <div>
        <img
          alt="Product image"
          src={sku.images[0] && imagor(sku.images[0].imageUrl)}
          width={256}
          height={256}
          className="rounded"
        />
      </div>

      <div className="flex items-baseline pt-6">
        <Heading size="300">{formatMoney(getSalesEntityPrice(sku))}</Heading>
        <div className="pl-2">
          <Paragraph size="300" color="text-textIcon-blackSecondary">
            {`${formatMoney(sku.price.costPrice)} / ${sku.measurementUnit}`}
          </Paragraph>
        </div>
      </div>
    </>
  );
};
