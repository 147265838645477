import { useSelector } from "react-redux";

import { getMinimumQuantityNumber } from "@web/common";
import { AddToCartUI } from "@web/common/components/cards/AddToCartUI";
import { ProductSku } from "@web/common/models/ProductSku";

import {
  RootState,
  clearGatherBasketEntry,
  setGatherBasketEntry,
  useAppDispatch,
} from "../../../store";

interface Props {
  sku: ProductSku;
}

export const AddToBasket: React.FC<Props> = ({ sku }) => {
  const productEntry = useSelector((state: RootState) => state.gather.basket.catalogItems[sku.id]);
  const itemsInBasket = productEntry?.quantity || 0;
  const dispatch = useAppDispatch();
  const minimumQuantityNumber = getMinimumQuantityNumber(sku);

  const skuId = sku.id;

  const handleChange = (val: number) => {
    if (val === 0) {
      dispatch(clearGatherBasketEntry(skuId));
    } else {
      dispatch(
        setGatherBasketEntry({
          quantity: val,
          sku,
        })
      );
    }
  };

  const lineItemTotal = null;

  const handlePlus = () => {
    handleChange(itemsInBasket + 1);
  };

  const handleMinus = () => {
    if (itemsInBasket - 1 < minimumQuantityNumber) {
      dispatch(clearGatherBasketEntry(skuId));
    } else {
      handleChange(itemsInBasket - 1);
    }
  };

  return (
    <AddToCartUI
      handlePlus={handlePlus}
      handleMinus={handleMinus}
      handleChange={handleChange}
      lineItemTotal={lineItemTotal}
      sku={sku}
      quantityInBasket={itemsInBasket}
      onAddToCart={() => handleChange(minimumQuantityNumber)}
    />
  );
};
