import { useFlag } from "@unleash/proxy-client-react";
import { useTranslation } from "react-i18next";

import { Label } from "@web/ui";

import { OrderRequisition } from "../../network/model";
import { OrdersTableRow } from "./components";

interface Props {
  dataTestId?: string;
  orders?: Array<OrderRequisition>;
  getUrl: (order: OrderRequisition) => string;
  openDeleteDraftModal?: (draftId: string) => void;
}

const Heading = ({ label, ...params }: Record<string, string>) => {
  return (
    <th {...params}>
      <Label size="300" color="text-neutral_700">
        {label}
      </Label>
    </th>
  );
};

export const LEGACY_OrdersTable = (props: Props) => {
  const { orders = [], getUrl, openDeleteDraftModal, dataTestId } = props;
  const { t } = useTranslation();
  const isOrderNameEnabled = useFlag("order-name");

  return (
    <table
      data-testid={dataTestId}
      className="w-full border-separate min-w-full break-words table-fixed"
      style={{ borderSpacing: "0 0.25rem" }}
    >
      <colgroup>
        <col />
        <col />
        <col />
        <col />
        <col />
        <col span={1} width="10%" />
      </colgroup>
      <thead>
        <tr>
          {isOrderNameEnabled && <Heading className="p-2 text-left" label="Order Name" />}
          <Heading className="p-2 text-left" label={t("common.requisitionTable.poNumber")} />
          <Heading className="p-2 text-left" label={t("common.requisitionTable.vessel")} />
          <Heading className="p-2 text-left" label={t("common.requisitionTable.port")} />
          <Heading className="p-2 text-left" label={t("common.requisitionTable.status")} />
          <Heading
            className="p-2 text-right nowrap"
            label={t("common.requisitionTable.grossAmount")}
          />
        </tr>
      </thead>
      <tbody>
        {orders.map((order: OrderRequisition, i) => (
          <OrdersTableRow
            key={order.id + i}
            order={order}
            getUrl={getUrl}
            openDeleteDraftModal={openDeleteDraftModal}
          />
        ))}
      </tbody>
    </table>
  );
};
