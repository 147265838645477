import { OrderInfo, OrderInfoFormValues } from "@web/common";
import FormLayout from "@web/common/layouts/FormLayout";
import { BasketEntry } from "@web/common/models/basket";
import { OrderRequisition } from "@web/common/network/model";
import { Money } from "@web/models";
import { Loading } from "@web/ui";

import { useVesselConfigurationQuery } from "src/hooks/useVesselConfiguration";

import { useApproveRequisitionMutation } from "../../../hooks/useApproveRequisitionMutation";
import { toApproveRequisitionItem, toEditItemList } from "./utils";

interface Props {
  lineItems: BasketEntry[];
  requisitionId: string;
  orderRequisition: OrderRequisition;
  totalPrice: Money;
}

export const RequisitionApproval = ({
  lineItems,
  requisitionId,
  orderRequisition,
  totalPrice,
}: Props) => {
  const vesselConfigurationQuery = useVesselConfigurationQuery(orderRequisition.vessel.id);
  const { mutate, isPending } = useApproveRequisitionMutation();
  const items = toEditItemList(lineItems);
  const hasEditedItems = items.length > 0;

  if (vesselConfigurationQuery.isPending || vesselConfigurationQuery.isFetching) {
    return <Loading />;
  }

  if (!vesselConfigurationQuery.data) {
    return null;
  }

  const submitOrderHandler = async (orderSummary: OrderInfoFormValues) => {
    // This guard is only to make the compiler happy, without major refactor of OrderInfo component.
    // In reality this will never happen thanks to the validation set up in OrderInfo component.
    if (!orderSummary.deliveryDate) {
      throw Error("Delivery date is not defined");
    }

    const result = {
      id: requisitionId,
      requestBody: {
        agent: orderSummary.agentInformation || {
          firstName: "",
          lastName: "",
          company: "",
          email: "",
          phoneNumber: "",
        },
        deliveryDate: orderSummary.deliveryDate,
        orderNotes: orderSummary.orderNotes,
        consolidated: orderSummary.consolidated ?? orderRequisition.consolidated,
        storageLabel: orderSummary.storageLabel,
        customerOrderId: orderSummary.customerOrderId,
        items: hasEditedItems
          ? items
          : orderRequisition.items.map((item) => toApproveRequisitionItem(item)),
        // Extra Items are not viewable or editable in Approval process in the Main App
        extraItems: orderRequisition.extraItems,
        invoiceAccountId: orderSummary.invoiceAccountId,
        warehouseId: orderSummary.warehouseId ?? orderRequisition?.warehouse?.id,
      },
    };

    if (orderRequisition.consolidated && !result.requestBody.warehouseId) {
      throw Error("WarehouseId is not defined");
    }

    mutate(result);
  };

  return (
    <FormLayout>
      <OrderInfo
        creationMode="REQUISITION_APPROVAL"
        grandTotal={hasEditedItems ? totalPrice : orderRequisition.totalGrossAmount}
        invoiceAccounts={vesselConfigurationQuery.data.vessel.invoiceAccounts}
        loading={isPending}
        nrLineItems={hasEditedItems ? items.length : orderRequisition.items.length}
        nrRfqItems={0}
        nrExtraItems={0}
        orderRequisition={orderRequisition}
        port={orderRequisition.port}
        submitForm={submitOrderHandler}
        vessel={orderRequisition.vessel}
        warehouses={vesselConfigurationQuery.data.warehouses ?? []}
        withOrderComment
      />
    </FormLayout>
  );
};
